import { images } from '../constants';

export const fullStackPortfolios = [
    {
        id: 1,
        Image: images.fullStack_portfolio_distriflow,
        title: 'Distriflow',
        category: 'Full Stack Developer',
        link: 'https://www.distriflow.com'
    },
    {
        id: 2,
        Image: images.fullStack_portfolio_billow,
        title: 'Billow',
        category: 'Full Stack Developer',
        link: 'http://66.94.125.228'
    },
    {
        id: 3,
        Image: images.fullStack_portfolio_la_poste_mobile,
        title: 'La POSTE MOBILE',
        category: 'Full Stack Developer',
        link: 'https://laposte.fr/'
    },
    {
        id: 4,
        Image: images.fullStack_portfolio_artemis,
        title: 'Artemis',
        category: 'Wordpress Developer',
        link: 'https://jurispromaroc.com/'
    },
    {
        id: 5,
        Image: images.fullStack_portfolio_cfm,
        title: 'CFM',
        category: 'Full Stack Developer',
        link: 'https://laposte.fr/'
    },
    {
        id: 6,
        Image: images.fullStack_portfolio_linkey,
        title: 'Linkey',
        category: 'Full Stack Develper',
        link: 'https://www.linkey.ae'
    },
    {
        id: 7,
        Image: images.fullStack_portfolio_paris_bord_de_marne,
        title: 'Paris Bords Sur Marne',
        category: 'Wordpress Developer',
        link: 'https://paris-bords-de-marne.com/'
    },
    {
        id: 8,
        Image: images.fullStack_portfolio_123_commerce_france,
        title: '123 commerce france',
        category: 'Wordpress Developer',
        link: 'https://www.123commerce-france.com/'
    },
    {
        id: 9,
        Image: images.fullStack_portfolio_alma_certif,
        title: 'ALMA CERTIF',
        category: 'Wordpress Developer',
        link: 'https://almacertif.fr/'
    },
    {
        id: 10,
        Image: images.fullStack_portfolio_pilotage_pch,
        title: 'Pilotage PCH',
        category: 'Full Stack Developer',
        link: 'https://laposte.fr/'
    },
    {
        id: 11,
        Image: images.fullStack_portfolio_pacte,
        title: 'Pacte',
        category: 'Full Stack Developer',
        link: 'https://laposte.fr/'
    }
];



export const backendPortfolios = [
    {
        id: 1,
        Image: images.backend_portfolio_cfm,
        title: 'Fiche Client Multicanales',
        category: 'Backend Developer',
        link: 'https://laposte.fr/'
    },
    {
        id: 3,
        Image: images.backend_portfolio_traceo,
        title: 'Traceo / UTE Tracing',
        category: 'Backend Developer',
        link: 'https://laposte.fr/'
    },
    {
        id: 5,
        Image: images.backend_portfolio_kaftan_pro,
        title: 'Kaftan Pro',
        category: 'Backend Developer',
        link: ''
    }
];



export const consultingPortfolios = [
    {
        id: 1,
        Image: images.consulting_portfolio_cdc_distriflow,
        title: 'Construction de cahier des charges - Distriflow',
        category: 'Fonctional & Technical Conception',
        link: ''
    },
    {
        id: 3,
        Image: images.consulting_portfolio_cdc_bontikote,
        title: 'Construction de cahier des charges - Bontikote',
        category: 'Fonctional & Technical Conception',
        link: ''
    }
];