
import { FaPaintBrush, FaFileVideo, FaSearchDollar } from 'react-icons/fa';
import { BsCodeSlash } from 'react-icons/bs';
import { MdPhotoCamera } from 'react-icons/md';
import { ImBullhorn } from 'react-icons/im';

const services = [
    {
        id: 1,
        icon: <BsCodeSlash />,
        title: 'Back-End Development',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 2,
        icon: <FaPaintBrush />,
        title: 'Front-End Development',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },
    {
        id: 3,
        icon: <MdPhotoCamera />,
        title: 'UI/UX Integration',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        
    },
    {
        id: 4,
        icon: <ImBullhorn />,
        title: 'Support IT',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
    },

];

export default services;