const experience = [

    {
        id: 1,
        duration: '2023 - 2024',
        title: 'full-stack developer',
        content: 'CNP ASSURANCE (France) - Distriflow (France)',
    },
    {
        id: 2,
        duration: '2018 - 2023',
        title: 'full-stack developer',
        content: 'LA POSTE (France) - Distriflow (France) - Billow (Canada) - TIB FINANCE (Canada)',
    },
    {
        id: 3,
        duration: '2013-2018',
        title: 'full-stack developer',
        content: 'Artemis (Morocco) - ucar (France) - tbib (Morocco)',
    },
 
];

export default experience;