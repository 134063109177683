import homeImage from '../assets/Home/Home-Image.jpg';

import fullStack_portfolio_distriflow from '../assets/Portfolio/full-stack/distriflow.png';
import fullStack_portfolio_billow from '../assets/Portfolio/full-stack/billow.png';
import fullStack_portfolio_la_poste_mobile from '../assets/Portfolio/full-stack/la-poste.jpg';
import fullStack_portfolio_artemis from '../assets/Portfolio/full-stack/artemis.png';
import fullStack_portfolio_cfm from '../assets/Portfolio/full-stack/la-poste.jpg';
import fullStack_portfolio_linkey from '../assets/Portfolio/full-stack/linkey.jpg';
import fullStack_portfolio_paris_bord_de_marne from '../assets/Portfolio/full-stack/paris-bords-de-marne.png';
import fullStack_portfolio_123_commerce_france from '../assets/Portfolio/full-stack/123-commerce-france.png';
import fullStack_portfolio_alma_certif from '../assets/Portfolio/full-stack/alma-certif.png';
import fullStack_portfolio_pilotage_pch from '../assets/Portfolio/full-stack/la-poste.jpg';
import fullStack_portfolio_pacte from '../assets/Portfolio/full-stack/la-poste.jpg';
import fullStack_portfolio_assanabil from '../assets/Portfolio/full-stack/1.png';
import fullStack_portfolio_default from '../assets/Portfolio/full-stack/1.png';

import backend_portfolio_default from '../assets/Portfolio/Graphic-Design/1.png';
import backend_portfolio_cfm from '../assets/Portfolio/backend/la-poste.jpg';
import backend_portfolio_traceo from '../assets/Portfolio/backend/la-poste.jpg';
import backend_portfolio_kaftan_pro from '../assets/Portfolio/backend/kaftan-pro.jpg';

import consulting_portfolio_default from '../assets/Portfolio/Photography/1.png';
import consulting_portfolio_cdc_distriflow from '../assets/Portfolio/Photography/1.png';
import consulting_portfolio_cdc_bontikote from '../assets/Portfolio/Photography/1.png';

import testimonial1 from '../assets/Testimonials/pic-1.png';
import testimonial2 from '../assets/Testimonials/pic-2.png';
import testimonial3 from '../assets/Testimonials/pic-3.png';
import testimonial4 from '../assets/Testimonials/pic-4.png';
import testimonial5 from '../assets/Testimonials/pic-5.png';


import blog1 from '../assets/Blogs/Blog-1.png';
import blog2 from '../assets/Blogs/Blog-2.png';
import blog3 from '../assets/Blogs/Blog-3.png';



const Images = {
    homeImage,

    fullStack_portfolio_distriflow,
    fullStack_portfolio_billow,
    fullStack_portfolio_la_poste_mobile,
    fullStack_portfolio_artemis,
    fullStack_portfolio_cfm,
    fullStack_portfolio_linkey,
    fullStack_portfolio_paris_bord_de_marne,
    fullStack_portfolio_123_commerce_france,
    fullStack_portfolio_alma_certif,
    fullStack_portfolio_pilotage_pch,
    fullStack_portfolio_pacte,
    fullStack_portfolio_assanabil,
    fullStack_portfolio_default,

    backend_portfolio_default,
    backend_portfolio_cfm,
    backend_portfolio_traceo,
    backend_portfolio_kaftan_pro,

    consulting_portfolio_default,
    consulting_portfolio_cdc_distriflow,
    consulting_portfolio_cdc_bontikote,

    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,
    testimonial5,

    blog1,
    blog2,
    blog3,
};

export default Images;