import React from 'react';
import './Home.css';

import { images } from '../../constants';
import { Button } from '../../components';

const Home = () => {
  return (

  <section className='home' id='home'>

    <div className='box-container'>

      <div className='content'> 

        <div className='text'>
          <h1>Abdelmalik BOUYACHFAR</h1>
          <h4>full stack <span>web developer</span></h4>
          <p>
            With 12 Years of Crafting Innovative Solutions<br/>
            If You're Seeking A fullstack Web Dev Maestro To Breathe Life Into Your Next Big Digital Initiative, Let's Connect And Bring Your Vision To Reality.
          </p>
          <Button link={'contact'} title={'contact me'} />
        </div>

      </div>

      <div className='image'>
        <img src={images.homeImage} alt={'portfolio-pic'}></img>
      </div>

    </div>

  </section>

  )
}

export default Home