 import React from 'react';
 import './Footer.css';
// import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

 
const Footer = () => {
  return (
    <footer className='footer'>
      <div className='content'>
        <p>designed by <span>Abdelmalik BOUYACHFAR</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;