import React from 'react';
import CountUp from 'react-countup';
import './About.css';

import { Heading, Button } from '../../components';

const About = () => { 
  return (
    <section className='about' id='about'>

      <Heading title={'about me'} span={'know about me'} />

      <div className='box-container'>

        <div className='content'>

          <h2>My name is Abdelmalik BOUYACHFAR</h2>
          <p>
            As a full stack web developer with 12 years of proven experience, I specialize in crafting cutting-edge digital solutions that drive business success. Proficient in a wide range of technologies including PHP, MySQL, JavaScript, CSS, and HTML, I have honed my skills working with leading frameworks like Laravel, Symfony, and WordPress.
            <br/>
            I've Played A Key Role In Delivering A Significant Number Of Impactful Web Projects, Including Complex Web Portals, Feature-Rich Web Applications, Engaging Websites, And Robust E-Commerce Platforms
          </p>
          <div className='about-info'>
            <h4>age: <span>36</span></h4>
            <h4>gender: <span>male</span></h4>
            <h4>language: <span>arabic, english, frensh</span></h4>
            <h4>work: <span>full stack web developer</span></h4>
            <h4>freelance: <span>available</span></h4>
          </div>
          <Button title={'download CV'} />

        </div> 

        <div class="counting">

          <div class="box">
            <CountUp class="count" end={12} duration={3} suffix={'+'} />
            <h3>years of experience</h3>
          </div>
          
          <div class="box">
              <CountUp class="count" end={20} duration={3} suffix={'+'} />
              <h3>projects completed</h3>
          </div>

        <div class="box">
          <CountUp class="count" end={8} duration={3} suffix={'+'} />
              <h3>coffee</h3>
          </div>
                      
          <div class="box">
          <CountUp class="count" end={10} duration={3} suffix={'+'} />
              <h3>happy clients</h3>
          </div>
      
        </div>

      </div>

    </section>
  )
}

export default About;