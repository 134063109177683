import { AiTwotonePhone } from 'react-icons/ai'; 
import { FaEnvelope } from 'react-icons/fa';
import { BsLinkedin, BsGithub } from 'react-icons/bs';


const contacts = [
    {
        id: 1,
        icon: <AiTwotonePhone />,
        title: 'Phone:',
        content: ['+33 7 53 43 03 18'],
        url: 'callto:+33 7 53 43 03 18'
    },
    {
        id: 2,
        icon: <AiTwotonePhone />,
        title: 'Watsapp:',
        content: ['+212 6 41 24 02 02'],
        url: 'callto:+212 6 41 24 02 02'
    },
    {
        id: 3,
        icon: <FaEnvelope />,
        title: 'email:',
        content: ['abdelmalik.bouyachfar@gmail.com'],
        url: 'mailto:abdelmalik.bouyachfar@gmail.com'
    },
    {
        id: 4,
        icon: <BsLinkedin />,
        title: 'linkedin:',
        content: ['Click Me'],
        url: 'http://www.linkedin.com/in/abdelmalik-bouyachfar-38136b53/',
    },
    {
        id: 5,
        icon: <BsGithub />,
        title: 'github:',
        content: ['Click Me'],
        url: 'http://www.github.com/abouyachfar',
    },
 
];

export default contacts;