import React from 'react';
import './Skills.css';

import { Heading, Skill } from '../../components';

const Skills = () => {
  return (
    <div className='skill-container'> 

      <Heading title={'my Skills'} span={'programming skills'} />
 
      <div class="skills"> 

          <div className='skill-item item1'>
              <Skill skill={'PHP'} progress={'95%'} width={'95%'} />
              <Skill skill={'MySQL'} progress={'95%'} width={'95%'} />
              <Skill skill={'javascript'} progress={'95%'} width={'95%'} />
              <Skill skill={'CSS'} progress={'95%'} width={'95%'} />
              <Skill skill={'HTML'} progress={'95%'} width={'95%'} />
          </div>

          <div class="skill-item item2">
              <Skill skill={'Symfony'} progress={'95%'} width={'95%'} />
              <Skill skill={'Angular'} progress={'75%'} width={'75%'} />
              <Skill skill={'VueJS'} progress={'75%'} width={'75%'} />
              <Skill skill={'React JS'} progress={'50%'} width={'50%'} />
              <Skill skill={'Bootstrap'} progress={'90%'} width={'90%'} />
          </div>

      </div>

    </div>
  )
}

export default Skills