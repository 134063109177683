const education = [

    {
        id: 1, 
        duration: '2008 - 2012',
        title: 'software engineering',
        content: 'engineering degree in computer science and multimedia',
    },
    {
        id: 2,
        duration: '2010 - 2012',
        title: 'software master',
        content: 'Master\'s degree in computer science from the French state',
    },
];

export default education;