import { images } from '../constants';


const testimonials = [

    { 
        id: 1,
        image: images.testimonial3,
        name: 'JS. Desfosses',
        title: 'Director',
        content: 'Abdelmalik delivered good work, and he had strong skills in PHP and Javascript. We will work with him again.',
        
    },
    {
        id: 2,
        image: images.testimonial2,
        name: 'Y. Ali',
        title: 'Product Owner',
        content: 'Best freelancer I\'ve worked with. Built a site from scratch. Great communication, did the job as requested and always willing to make changes to make the project better.',
    },
    {
        id: 3,
        image: images.testimonial1,
        name: 'T. Sanchez',
        title: 'Product Director',
        content: 'Top Quality service ! If you are searching a professional developper. Abdelmalik is your man!',
    }

];

export default testimonials;